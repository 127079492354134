import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Box, Text } from "theme-ui";
import styles from "./shop-banner.style";
/* import Img from "gatsby-image"; */
import AnnouncementBanner from "../color-divider/announcement-banner";
import { RichText } from "prismic-reactjs";
import { linkResolver } from "../../utils/linkResolver";

export const query = graphql`
  query {
    allPrismicShop {
      edges {
        node {
          data {
            title
            header_title {
              text
              raw
            }
            banner_image {
              fluid(maxWidth: 1575) {
                ...GatsbyImgixFluid_noBase64
              }
            }
            description {
              text
            }
            header_description {
              text
            }
            announcement_text {
              raw
            }
          }
        }
      }
    }
  }
`;

const ShopBanner: React.FC<{}> = () => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${query}`}
    render={(data) => {
      const prismic = data.allPrismicShop.edges[0].node.data;
      /* const heading = prismic?.title; */
      const headerHeading = prismic?.header_title;
      const cgTitleText = RichText.render(headerHeading?.raw, linkResolver);
      /* const image = prismic?.banner_image; */
      /* const description = prismic?.description?.text; */
      const headerDescription = prismic?.header_description?.text;
      const text = prismic?.announcement_text?.raw;
      return (
        <Box sx={styles.banner}>
          <Box sx={styles.imageWrap}>
            {/* <Img fluid={image?.fluid} style={{ height: "100%" }} /> */}
          </Box>
          <Box sx={styles.bannerWrap}>
            <Box sx={styles.bannerInfo}>
              {/* {heading && (
                <Text as="h1" sx={styles.bannerHeading}>
                  {heading}
                </Text>
              )}
              {description && (
                <Text as="h2" sx={styles.bannerSubHeading}>
                  {description}
                </Text>
              )} */}
              {cgTitleText && (
                <Text as="h1" sx={styles.bannerHeading}>
                  {cgTitleText}
                </Text>
              )}
              {headerDescription && (
                <Text as="h2" sx={styles.bannerSubHeading}>
                  {headerDescription}
                </Text>
              )}
            </Box>
          </Box>
          {text && <AnnouncementBanner fullWidth text={text} />}
          <Box sx={{ position: "absolute", bottom: "140px" }} id="main" />
        </Box>
      );
    }}
  />
);

export default ShopBanner;
