import { useRef, useEffect, Fragment } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { Box, Text } from "theme-ui";
import { graphql, StaticQuery, Link } from "gatsby";
import Icon from "../category-icon";
import styles from "./category-dropdown.style";
import kebabCase from "lodash/kebabCase";

export const menuStaticQuery = graphql`
  query {
    allShopifyProduct {
      nodes {
        id
        title
        handle
        vendor
      }
    }
    allShopifyCollection {
      nodes {
        title
        handle
        products {
          variants {
            availableForSale
          }
        }
      }
    }
    allPrismicShop {
      edges {
        node {
          data {
            category_dropdown {
              category_menu_group {
                document {
                  ... on PrismicShopCategory {
                    data {
                      category_heading
                      collection_names {
                        collection
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CategoryDropdownMenu: React.FC<{
  onClick?: () => void;
  pathPrefix?: string;
}> = ({ onClick, pathPrefix = "/shop" }) => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${menuStaticQuery}`}
    render={(data) => {
      // Active collections - Return a list of collections that have at least one active product
      const products = data.allShopifyProduct.nodes;
      const collections = data.allShopifyCollection.nodes;
      const activeCollections = collections?.filter((collection) => {
        return collection.products?.some((product) =>
          product?.variants?.some((variant) => variant?.availableForSale)
        );
      });

      // Vendors - create a vendor array from products and match to active collections
      const vendors = new Set();
      products.forEach((node) => {
        if (node.vendor) {
          vendors.add(node.vendor);
        }
      });
      const vendorArray = Array.from(vendors);
      const vendorList = vendorArray?.reduce((acc, current) => {
        const x = acc.find((item) => item === current);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      const vendorPages = activeCollections?.filter((collection) => {
        return vendorList?.some((vendor) => vendor === collection.title);
      });

      const onClick = () => {
        return;
      };
      const pathPrefix = "/shop";

      // Categories - match prismic categories to Shopify Collections
      const prismicCategoryGroups =
        data.allPrismicShop.edges[0].node.data.category_dropdown;

      // Mobile Menu
      const mainMenu = useRef(null);
      const windowSize = useWindowSize();
      useEffect(() => {
        if (windowSize && windowSize < 1081) {
          const menu = mainMenu.current.children;
          for (let index = 0; index < menu.length; index++) {
            const element = menu[index];
            element.addEventListener("click", function () {
              element.classList.toggle("active");
            });
          }
        }
      }, [windowSize]);

      return (
        <Box sx={styles.dropdownWrap}>
          <Box className="mainMenu" as="ul" ref={mainMenu} sx={styles.mainMenu}>
            <Box
              as="li"
              className={`dropdown-items-0`}
              key="all-items"
              sx={styles.mainMenuItem}
            >
              <Link
                activeClassName="active"
                to={`/all-products#main`}
                onClick={onClick}
              >
                ALL PRODUCTS
              </Link>
            </Box>
            <Box as="li" className="dropdown-items" sx={styles.mainMenuItem}>
              <Text as="span" className="uppercase">
                MAKERS
              </Text>
              {/*<Icon name="chevron-down" />*/}
              {vendorPages && (
                <Box as="ul" sx={styles.submenu} className="vendors">
                  {vendorPages.map((subItem, index) => {
                    return (
                      <Box as="li" key={`${subItem}-${index}`}>
                        <Link
                          activeClassName="active"
                          to={`/shop/${subItem.handle}#main`}
                        >
                          {subItem.title}
                        </Link>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
            {prismicCategoryGroups ? (
              prismicCategoryGroups.map((group, index) => {
                const menu = group.category_menu_group;
                const title = menu?.document?.data?.category_heading;

                const filteredContents =
                  menu?.document?.data?.collection_names.filter((coll) => {
                    return activeCollections.some(
                      (active) => active.title === coll.collection
                    );
                  });

                if (filteredContents?.length) {
                  return (
                    <Box
                      as="li"
                      key={`${title}-${index}`}
                      className={`dropdown-items-${group}`}
                      sx={styles.mainMenuItem}
                    >
                      <Text as="span" className="uppercase">
                        {title}
                      </Text>
                      {/*<Icon name="chevron-down" />*/}
                      <Box as="ul" sx={styles.submenu} className="categories">
                        {menu.document.data.collection_names?.map(
                          (subItem, index) => {
                            const collectionSlug = kebabCase(
                              subItem.collection
                            );
                            if (
                              activeCollections.some(
                                (collection) =>
                                  collection.handle === collectionSlug
                              )
                            ) {
                              return (
                                <Box as="li" key={`${collectionSlug}-${index}`}>
                                  <Link
                                    activeClassName="active"
                                    to={`/shop/${collectionSlug}#main`}
                                  >
                                    {subItem.collection}
                                  </Link>
                                </Box>
                              );
                            } else {
                              return null;
                            }
                          }
                        )}
                      </Box>
                    </Box>
                  );
                } else return null;
              })
            ) : (
              <span />
            )}
          </Box>
        </Box>
      );
    }}
  />
);

export default CategoryDropdownMenu;
