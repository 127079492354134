/** @jsx jsx */
import { Flex, jsx } from 'theme-ui'
import styles from "./announcement-banner.style";
import { RichText } from "prismic-reactjs";

interface Props {
  text: any;
  fullWidth?: boolean;
}

const AnnouncementBanner: React.FC<Props> = ({ text, fullWidth }) => {
  return (
    <Flex sx={styles.wrapper} className={fullWidth ? "full" : "max"}>
      <div className="regular">{RichText.render(text)}</div>
    </Flex>
  );
};

export default AnnouncementBanner;