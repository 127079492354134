const styles = {
  banner: {
    position: "relative" as "relative",
    display: "inline-block",
    width: "100%",
    height: "100%",
    marginTop: ["30px", "50px", 0],
  },
  imageWrap: {
    width: "100%",
    height: ["360px", "350px", "380px"],
    img: {
      height: "100%",
    },
  },
  bannerWrap: {
    maxWidth: "1250px",
    width: ["100%", "90%"],
    position: "absolute" as "absolute",
    transform: "translate(-50%, -50%)",
    top: ["calc(50% - 30px)", ""],
    left: "50%",
    "@media screen and (max-width: 1400px)": {
      left: "60%",
    },
    "@media screen and (max-width: 590px)": {
      left: "50%",
    },
  },
  bannerInfo: {
    display: "flex",
    justifyContent: "center",
    "flex-direction": "column",
    alignItems: "center",
    width: "100%",
    color: "cgTeal",
    "@media screen and (min-width:32em)": {
      width: "100%",
      marginTop: "30px",
    },
    "@media screen and (min-width:48em)": {
      width: "80%",
    },
    "@media screen and (min-width:64em)": {
      width: "70%",
    },
    "@media screen and (min-width:86rem)": {
      /* maxWidth: "700px", */
      width: "100%",
    },
    opacity: "0.9",
    padding: "25px",
    p: {
      margin: "10px",
      fontSize: ["14px", "16px", "18px"],
      width: ["100%", "80%", "70%"],
    },
    h3: {
      margin: "20px",
    },
  },
  bannerHeading: {
    position: "relative" as "relative",
    fontSize: ["35px", "50px", "60px"],
    letterSpacing: "1px",
    lineHeight: "1.1em",
    fontWeight: "600",
    marginTop: "0",
    marginBottom: ".17em",
    zIndex: "10",
  },
  bannerSubHeading: {
    fontSize: ["17px", "18px", "20px"],
    fontWeight: 500,
    lineHeight: ["1.4em", "1.6em"],
    fontFamily: "Montserrat",
    margin: ["0 0 20px", "0 0 40px"],
    padding: "5px 0",
    zIndex: "10",
    "text-align": "center",
  },
};

export default styles;
