const styles = {
  wrapper: {
    padding: "0 80px",
    "@media only screen and (max-width: 590px)": {
      padding: "0 40px",
    },
  },
  page: {
    display: "flex",
    marginBottom: ["40px", "50px", "60px", "80px"],
    ".shop-main": {
      flex: 5,
      height: "100%",
    },
    ".shop-sidebar": {
      flex: 1.3,
    },
    "@media only screen and (max-width: 1280px)": {
      display: "block",
      ".shop-sidebar": {
        "& >div": {
          width: "90%",
        },
      },
    },
  },
  shoppingGuide: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "@media only screen and (max-width: 1023px)": {
      "flex-direction": "column",
    },
  },
  suppliers: {
    fontSize: ["28px", "33px", "41px"],
    color: "cgTeal",
    marginTop: ["20px", "30px"],
    fontWeight: 600,
    marginBottom: ["1.2em !important", ".8em !important"],
    "text-align": "center",
  },
  description: {
    fontSize: ["17px", "19px"],
    padding: "0",
    "text-align": "center",
    p: {
      m: 0,
      lineHeight: "1.5em",
    },
    "@media only screen and (min-width: 560px)": {
      padding: "20px 25px 15px",
    },
    "@media only screen and (min-width: 1200px)": {
      padding: "25px 50px 20px",
    },
  },
};

export default styles;
