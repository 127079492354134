/** @jsx jsx */
import React from "react";
import { Link } from "gatsby";
import { Box, Text, jsx } from "theme-ui";
import styles from "./vendor-slider.style";
import kebabCase from "lodash/kebabCase";
import Carousel from "../carousel/carousel";

interface Props {
  vendors: any;
}

const VendorSlider: React.FC<Props> = ({ vendors }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 100000, min: 1024 },
      items: 6,
      partialVisibilityGutter: 40,
    },
    ipad: {
      breakpoint: { max: 1024, min: 726 },
      items: 4,
      partialVisibilityGutter: 50,
    },
    lgmobile: {
      breakpoint: { max: 725, min: 501 },
      items: 3,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
      partialVisibilityGutter: 50,
    },
  };
  return (
    <Box sx={styles.wrapper}>
      <Carousel visible={true} ssr={true} responsive={responsive} gutter="10px">
        {vendors?.map((vendor: any, index: number) => {
          const logo = vendor.vendor.document?.data?.logo?.localFile?.publicURL;
          const vendorSlug = kebabCase(
            vendor.vendor.document?.data?.vendor_name
          );
          if (logo) {
            return (
              <Link to={`/shop/${vendorSlug}#main`} key={`image-${index}`}>
                <img src={logo} className="carousel-image" />
              </Link>
            );
          } else return null;
        })}
      </Carousel>
    </Box>
  );
};

export default VendorSlider;
