/** @jsx jsx */
import { Box, Text, jsx } from 'theme-ui'
import { Link } from "gatsby";
import Img from "gatsby-image";
import styles from "./custom-preview.style";

const CustomPreview: React.FC<Props> = ({
  content,
  image,
  tall
  }) => {
  return (
    <Box sx={styles.wrapper} className={tall ? "tall" : "short"}>
      <Link to={`/${content.uid}`}>
       <Img fluid={image} />
        <Text as="h3">{content.post_title.text}</Text>
        <Text as="span" sx={styles.readMore}>Read More</Text>
      </Link>
    </Box>
  )
};

export default CustomPreview;