import React from "react";
import { graphql } from "gatsby";
import { Box, Flex } from "theme-ui";
import styles from "../components/shop/shop.style";
import SEO from "../components/seo";
import PrimaryLayout from "../components/layout/primary/primary";
import ProductGrid from "../components/product-grid/product-grid";
import Pagination from "../components/pagination/pagination";
import CategoryDropdownMenu from "../components/category-dropdown/category-dropdown";
import ShopBanner from "../components/shop-banner/shop-banner";
import ContentContainer from "../components/container/content-container";
import CustomPreview from "../components/blog/custom-preview";
import VendorSlider from "../components/shop/vendor-slider";
import FeaturedBanner from "../components/shop/featured-banner";
import BlogPostPreview from "../components/blog/blog-post-preview";
import { MakeMixedVariantArray } from "../utils";

const AllProductsPage = ({ data, pageContext }: any) => {
  const products = data.allShopifyProduct.nodes;
  const { currentPage, numPages, postsLimit } = pageContext;
  const currentSlug = `/all-products`;

  // Turn products into an array of variants and paginate
  const variantsArray = MakeMixedVariantArray(products);

  const activeVariants = variantsArray?.filter(
    (variant) => variant.available === true
  );
  const sliceIndex = (currentPage - 1) * postsLimit;
  const sliceLimit = postsLimit * (currentPage - 1) + 12;
  const variantsSlice = activeVariants?.slice(sliceIndex, sliceLimit);

  // Divide the array
  const sliceOne = variantsSlice?.slice(0, 6);
  const sliceTwo = variantsSlice?.slice(6, 12);

  const shopPage = data.allPrismicShop.edges[0].node.data;
  const meta = shopPage.meta_description;

  const featured = shopPage.featured_collections;
  const articleImageSm = shopPage.sm_featured_article_image;
  const articleLinkSm = shopPage.sm_featured_article_link?.document.uid;
  const articleImageWide = shopPage.wide_featured_article_image.fluid;
  const articleWide = shopPage.wide_featured_article_link;
  const shopping = shopPage.shopping_guide;
  const vendors = shopPage.vendor_carousel;

  // Match featured collections to Shopify products
  const filterFeatured = activeVariants.filter((variant) => {
    return featured.some(
      (feature) => feature.product.document.data.sku === variant.sku
    );
  });
  let matchedFeatured = [];
  featured.forEach((item) => {
    let matching = activeVariants.find(
      (variant) => variant.sku === item.product.document.data.sku
    );
    matchedFeatured.push({ headline: item.headline, product: matching });
  });

  return (
    <PrimaryLayout
      fluid={true}
      homeLink="/"
      shop
      //pathPrefix="/all-products"
      showNoticeBar={false}
    >
      <SEO title="Shop all products" description={meta} />
      <ShopBanner />
      <CategoryDropdownMenu />
      <ContentContainer width="1078px">
        <Box sx={styles.page}>
          <Box className="shop-main">
            <ProductGrid
              id="category"
              products={sliceOne}
              withLink={true}
              isCategoryProduct={true}
              shopPage={true}
            />
            {filterFeatured.length >= 4 && (
              <FeaturedBanner
                features={matchedFeatured}
                image={articleImageSm}
                link={articleLinkSm}
              />
            )}
            <ProductGrid
              id="category"
              products={sliceTwo}
              withLink={true}
              isCategoryProduct={true}
              shopPage={true}
            />
            {numPages > 1 ? (
              <Pagination
                numPages={numPages}
                currentPage={currentPage}
                currentSlug={currentSlug}
                isProductPage={true}
              />
            ) : (
              <Box sx={{ paddingBottom: "30px" }} />
            )}
          </Box>
        </Box>
      </ContentContainer>
      <ContentContainer>
        <Box sx={styles.wideArticle}>
          {articleImageWide && articleWide && (
            <CustomPreview content={articleWide} image={articleImageWide} />
          )}
        </Box>
        <Flex sx={styles.shoppingGuide}>
          {shopping?.map((post) => {
            return (
              <BlogPostPreview
                key={post?.article.document.uid}
                content={post?.article.document}
                previewStyle="stories"
                archive
                hideCat
              />
            );
          })}
        </Flex>
        {vendors && <VendorSlider vendors={vendors} />}
      </ContentContainer>
    </PrimaryLayout>
  );
};

export const query = graphql`
  query NewProductList($productWidth: Int = 380) {
    allShopifyProduct(sort: { order: DESC, fields: updatedAt }) {
      ...ShopifyProducts
    }
    allPrismicShop {
      edges {
        node {
          data {
            meta_description
            featured_collections {
              headline
              product {
                document {
                  ... on PrismicProduct {
                    data {
                      sku
                      single_title
                    }
                  }
                }
              }
            }
            shopping_guide {
              article {
                document {
                  ... on PrismicBlogContentModel {
                    id
                    data {
                      featured_image {
                        fluid(maxWidth: 450) {
                          ...GatsbyImgixFluid_noBase64
                        }
                      }
                      post_title {
                        text
                      }
                      excerpt
                    }
                    uid
                  }
                }
              }
            }
            sm_featured_article_image {
              fluid(maxWidth: 300) {
                ...GatsbyImgixFluid_noBase64
              }
            }
            sm_featured_article_link {
              document {
                ... on PrismicBlogContentModel {
                  uid
                }
              }
            }
            vendor_carousel {
              vendor {
                document {
                  ... on PrismicVendor {
                    data {
                      vendor_name
                      logo {
                        localFile {
                          publicURL
                        }
                      }
                    }
                  }
                }
              }
            }
            wide_featured_article_image {
              fluid(maxWidth: 1000) {
                ...GatsbyImgixFluid_noBase64
              }
            }
            wide_featured_article_link {
              document {
                ... on PrismicBlogContentModel {
                  uid
                  data {
                    post_title {
                      text
                    }
                    story_content_type {
                      document {
                        ... on PrismicStoryType {
                          id
                          data {
                            name
                          }
                        }
                      }
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AllProductsPage;
