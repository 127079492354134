const styles = {
  wrapper: {
    "&.short": {
      paddingBottom: ["40px", "45px", "90px"],
      ".gatsby-image-wrapper": {
        height: "328px"
      },
    },
    "&.tall": {
      paddingBottom: ["10px", "15px", "20px"],
      ".gatsby-image-wrapper": {
        height: "480px"
      },
    },
    a: {
      textDecoration: "none",
      color: "subHeadingColor"
    },
    h3: {
      color: "cgTeal",
      fontSize: ["27px","32px", "40px"],
      fontWeight: "400",
      paddingTop: '.7rem',
    }
  },
  readMore: {
    display: 'inline-block',
    fontStyle: 'italic',
    paddingBottom: '3px',
    borderBottom: '1px solid',
    marginBottom: '3px',
    paddingTop: '1.1rem',
    transition: '.2s',
    fontSize:'14px',
    ':hover': {
      color: 'cgGold'
    },
    '@media only screen and (min-width: 560px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width: 890px)': {
      paddingTop: '.8rem',
    },
  },
  catHeading: {
    display: 'inline-block',
    color: 'cgGold',
    fontSize:'18px',
    paddingBottom: '.5rem',
    '@media only screen and (min-width: 560px)': {
      fontSize: '23px',
      paddingBottom: '1rem',
    },
  },
}

export default styles;