/** @jsx jsx */
import { Box, Flex, Text, jsx } from 'theme-ui'
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import Img from "gatsby-image";
import styles from "./featured-banner.style";

const FeaturedBanner: React.FC<Props> = ({
  features,
  image,
  link
  }) => {
  const productOne = features[0].product
  const productTwo = features[1].product
  const productThree = features[2].product
  const productFour = features[3].product
  
  return (
    <Flex sx={styles.wrapper}>
      <Flex sx={styles.sideBanner}>
        <Box sx={styles.overlayWrap} className="side">
          <Link to={`/product/${productOne.handle}?id=${productOne.id}`}>
            <GatsbyImage image={productOne.image.gatsbyImageData} alt={productOne.title}/>
            <Text className="text" as="h2">{features[0].headline}</Text>
            <div className="overlay" />
          </Link>
        </Box>
        <Box sx={styles.overlayWrap} className="side">
          <Link to={`/product/${productTwo.handle}?id=${productTwo.id}`}>
            <GatsbyImage image={productTwo.image.gatsbyImageData} alt={productTwo.title}/>
            <Text className="text" as="h2">{features[1].headline}</Text>
            <div className="overlay" />
          </Link>
        </Box>
      </Flex>
      <Box sx={styles.overlayWrap} className="center">
        <Link to={`/${link}`}>
          <Img fluid={image?.fluid} />
        </Link>
      </Box>
      <Flex sx={styles.sideBanner}>
        <Box sx={styles.overlayWrap} className="side">
          <Link to={`/product/${productThree.handle}?id=${productThree.id}`}>
            <GatsbyImage image={productThree.image.gatsbyImageData} alt={productThree.title}/>
            <Text className="text" as="h2">{features[2].headline}</Text>
            <div className="overlay" />
          </Link>
        </Box>
        <Box sx={styles.overlayWrap} className="side">
          <Link to={`/product/${productFour.handle}?id=${productFour.id}`}>
            <GatsbyImage image={productFour.image.gatsbyImageData} alt={productFour.title}/>
            <Text className="text" as="h2">{features[3].headline}</Text>
            <div className="overlay" />
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}

export default FeaturedBanner;