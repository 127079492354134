const styles = {
  dropdownWrap: {
    backgroundColor: "#054752",
    marginTop: 0,
    marginBottom: "20px",
    minHeight: "55px",
    position: "sticky" as "sticky",
    top: "140px",
    zIndex: 1000,
    "@media only screen and (max-width: 1080px)": {
      marginBottom: "0",
      position: "initial" as "initial",
      top: 0,
    },
  },
  mainMenu: {
    position: "relative" as "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    maxWidth: "1040px",
    width: "95%",
    margin: "0 auto",
    "@media only screen and (max-width: 1080px)": {
      marginX: 0,
      width: "100%",
      "flex-direction": "column",
      alignItems: "flex-start",
      padding: "30px 20px 20px",
    },
    li: {
      listStyle: "none",
      "a, span": {
        cursor: "pointer",
        fontWeight: "500",
        display: "flex",
        alignItems: "center",
        fontSize: 17,
        textDecoration: "none",
        transition: "color 0.2s ease",
        color: "#fff",
        "@media only screen and (max-width: 1080px)": {
          width: "100%",
          /* color: "#054752", */
        },
        "&.active": {
          color: "#D79A2B",
        },
      },
    },
  },
  mainMenuItem: {
    display: "flex",
    alignItems: "center",
    position: "relative" as "relative",
    paddingX: 16,
    transition: ".2s all ease",
    ".uppercase": {
      "text-transform": "uppercase",
    },
    "@media only screen and (max-width: 1080px)": {
      width: "100%",
      marginX: 0,
      alignItems: "flex-start",
      "flex-direction": "column",
    },
    "> a, > span": {
      color: "menu",
      fontSize: 1,
      minHeight: [50, 55],
    },
    "> svg": {
      stroke: "#013766",
      marginX: "5px",
      height: "15px",
      width: "15px",
      cursor: "pointer",
      "@media only screen and (max-width: 1080px)": {
        position: "absolute" as "absolute",
        top: "25px",
        right: 0,
      },
    },
    ":hover": {
      "@media only screen and (min-width: 1081px)": {
        backgroundColor: "#3c5f66",
      },
      ul: {
        opacity: 1,
        visibility: "visible" as "visible",
        "@media only screen and (max-width: 1080px)": {
          opacity: 0,
          visibility: "hidden" as "hidden",
          "&.active": {
            opacity: 1,
            visibility: "visible" as "visible",
          },
        },
        li: {
          marginY: 0,
        },
      },
    },
    "&.active": {
      ul: {
        opacity: 1,
        visibility: "visible" as "visible",
        height: "max-content",
        li: {
          marginY: 0,
        },
      },
    },
  },
  submenu: {
    opacity: 0,
    visibility: "hidden" as "hidden",
    position: "absolute" as "absolute",
    backgroundColor: "white",
    left: 0,
    paddingTop: 9,
    paddingBottom: 12,
    paddingX: 0,
    top: "100%",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    boxShadow: "0 2px 4px rgba(41, 41, 41, 0.06)",
    transition: "opacity 0.2s ease",
    zIndex: 1,
    "&.vendors": {
      columnCount: 3,
      width: "680px",
      "@media only screen and (max-width: 890px)": {
        width: "500px",
        columnCount: 2,
      },
      "@media only screen and (max-width: 550px)": {
        width: "100%",
        columnCount: 1,
      },
    },
    "&.categories": {
      columnCount: 2,
      width: "400px",
      "@media only screen and (max-width: 890px)": {
        width: "500px",
      },
      "@media only screen and (max-width: 550px)": {
        width: "100%",
        columnCount: 1,
      },
    },
    "@media only screen and (max-width: 1080px)": {
      borderTop: 0,
      borderRadius: 0,
      borderLeftWidth: 3,
      borderLeftStyle: "solid",
      borderLeftColor: "muted",
      boxShadow: "none",
      position: "inherit" as "inherit",
      padding: 0,
      height: 0,
      top: 0,
    },
    li: {
      //justifySelf: 'center',
      marginY: -1,
      transition: "margin 0.2s ease",
      display: "inline-block",
      width: "100%",
      color: "#054752 !important",
      a: {
        color: "#535F61",
        paddingY: 10,
        paddingX: 20,
        fontSize: "14px",
        position: "relative" as "relative",
        "@media only screen and (min-width: 1081px)": {
          "&:hover": {
            color: "#D79A2B",
          },
          "&.active": {
            color: "#D79A2B",
          },
        },
        "@media only screen and (max-width: 1080px)": {
          "&:hover, &.active": {
            color: "#D79A2B",
            fontWeight: "600",
          },
        },
      },
    },
  },
};

export default styles;
