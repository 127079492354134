const styles = {
  wrapper: {
    backgroundColor: "cgGold",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
    "text-align": "center",
    "text-transform": "uppercase",
    letterSpacing: ".13",
    minHeight: "20px",
    padding: "8px 14px",
    "@media only screen and (min-width: 590px)": {
      minHeight: "50px",
    },
    "&.full": {
      maxWidth: "100%",
    },
    "&.max": {
      margin: "0 auto",
      maxWidth: "2200px",
    },
    p: {
      margin: "5px 0 0",
      lineHeight: "18px",
    },
    strong: {
      fontWeight: 700,
      fontSize: "17px",
      marginRight: "8px",
    },
    ".regular": {
      fontWeight: 400,
      fontSize: "16px",
    },
    span: {
      "@media only screen and (min-width: 1100px)": {
        maxWidth: "1100px",
        padding: "1px 20px",
      },
    },
  },
};

export default styles;
