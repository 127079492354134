const styles = {
  wrapper: {
    "text-align": "center",
    maxWidth: "1028px",
    padding: "40px 10px",
    "flex-direction": "column",
    justifyContent: "space-between",
    color: "#fff",
    "@media only screen and (min-width: 560px)": {
      padding: "60px 10px",
    },
    "@media only screen and (min-width: 890px)": {
      "flex-direction": "row",
      padding: "80px 10px",
    },
    a: {
      color: "white",
      overflow: 'hidden',
      display: 'block',
    },
  },
  overlayWrap: {
    position: "relative",
    ".overlay": {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(180deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0) 100%)',
      zIndex: 5,
    },
    ".text": {
      fontSize: "32px",
      letterSpacing: ".8px",
      width:  "90%",
      transition: "all .3s ease",
      display: "block",
      zIndex: 10,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    "&.center": {
      width: "100%",
      margin: "20px 0",
      ".gatsby-image-wrapper": {
        height: "310px",
      },
      "@media only screen and (min-width: 890px)": {
        width: "40%",
        margin: "0 20px 0 0",
        ".gatsby-image-wrapper": {
          height: "410px",
        },
      },
    },
    "&.side": {
      width: "100%",
      ":first-of-type": {
        marginBottom: "20px",
      },
      ".gatsby-image-wrapper": {
        height: "195px",
        
      },
      "@media only screen and (min-width: 560px)": {
        ":first-of-type": {
          marginRight: "20px",
          marginBottom: 0,
        },
      },
      "@media only screen and (min-width: 890px)": {
        ":first-of-type": {
          marginRight: 0,
          marginBottom: "20px",
        },
      },
    }
  },
  sideBanner: {
    width: "100%",
    "flex-direction": "column",
    ".gatsby-image-wrapper": {
      height: "195px",
    },
    "@media only screen and (min-width: 560px)": {
      "flex-direction": "row",
    },
    "@media only screen and (min-width: 890px)": {
      width: "30%",
      "flex-direction": "column",
      ":first-of-type": {
        marginRight: "20px",
      },
    },
  }
}

export default styles;