const styles = {
  wrapper: {
    display: "grid",
    width: "100%",
    paddingTop: ["30px", "40px", "20px"],
    paddingBottom: ["40px", "60px", "80px"],
    /* ".carousel-button-group": {
      opacity: 0,
      visibility: "hidden" as "hidden",
      transition: "all 0.25s ease",
    },
    "&:hover": {
      ".carousel-button-group": {
        opacity: 1,
        visibility: "visible" as "visible",
      },
    },
    "@media only screen and (max-width:1080px)": {
      ".carousel-button-group": {
        opacity: 0,
        visibility: "hidden" as "hidden",
      },
      "&:hover": {
        ".carousel-button-group": {
          opacity: 0,
          visibility: "hidden" as "hidden",
        },
      },
    }, */
    img: {
      width: ["70px", "95px", "100px"],
      "@media only screen and (max-width: 560px)": {},
    },
    "ul.react-multi-carousel-track": {
      alignItems: "center",
      justifyContent: "center",
    },
  },
};

export default styles;
